<template>
  <v-container fluid tag="section">
    <loading
      :active.sync="isLoading"
      color="#fe4a4b"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />

    <v-card-text>
      <v-container class="mt-n10">
        <v-row>
          <v-col cols="14" sm="2" md="2">
            <v-row dense class="mb-5">
              <v-btn color="primary" @click="backToMenu">
                <v-icon color="white">
                  mdi-arrow-left
                </v-icon>
                <span style="font-weight:bold;"> Back </span>
              </v-btn>
            </v-row></v-col
          >
          <v-col cols="14" sm="2" md="10">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn color="primary" dark @click="downloadCSV">
                  <v-icon color="white">
                    mdi-download-circle
                  </v-icon>
                  <v-card-text class="white--text font-weight-bold">
                    CSV
                  </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
          <!-- <v-col cols="14" sm="6" md="10">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn color="primary" dark @click="dialog = true">
                  <v-icon color="white">
                    mdi-plus-circle
                  </v-icon>
                  <v-card-text class="white--text font-weight-bold">
                    Add New Member
                  </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card-text>
    <v-container class="mt-n5">
      <material-card
        color="primary"
        title="ATTENDANCE"
        :imageSrc="require('@/assets/logoRR.png')"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <div
                style="font-weight:bold; font-size:20px; margin-bottom:20px;"
              >
                MEMBER INFO
              </div>

              <div style=" font-size:17px; padding:20px;" v-if="memberInfo">
                <div>Member No : {{ memberInfo.memberNumber }}</div>
                <div>First Name : {{ memberInfo.firstName }}</div>
                <div>Last Name : {{ memberInfo.lastName }}</div>
                <div>
                  Date of Birth : {{ memberInfo.dateOfBirth.year }}/{{
                    memberInfo.dateOfBirth.month
                  }}/{{ memberInfo.dateOfBirth.day }}
                </div>
                <div>
                  Gender :
                  <span v-if="memberInfo.gender == 0">Male</span>
                  <span v-if="memberInfo.gender == 1">Female</span>
                </div>
                <div>E-Mail: {{ memberInfo.email }}</div>
                <div>Mobile: {{ memberInfo.mobile }}</div>
                <div>Telephone: {{ memberInfo.telephone }}</div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div>
                <div
                  style="font-weight:bold; font-size:20px; margin-bottom:20px;"
                >
                  ATTENDANCE INFO
                </div>
                <v-row>
                  <v-col
                    style="font-weight:bold; font-size:15px;"
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    In Time
                  </v-col>
                  <v-col
                    style="font-weight:bold; font-size:15px;"
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    Out Time</v-col
                  >
                </v-row>

                <div v-for="(attendance, i) in memberInfo.attendances" :key="i">
                  <v-row>
                    <v-col cols="6" sm="6" md="6">
                      {{ format_date(attendance.inTime) }}
                      <span v-if="attendance.inTime == null"> - </span>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      {{ format_date(attendance.outTime) }}
                      <span v-if="attendance.outTime == null"> - </span>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-5 mb-5"></v-divider>
                </div>
              </div>
            </v-col>
          </v-row>

          <div v-if="!memberInfo">
            No Attendance Info
          </div>
        </v-card-text>
      </material-card>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var moment = require("moment");

export default {
  components: { Loading },
  data: () => ({
    isLoading: false,
    loadingSize: 128,
    loadingFullPage: true,

    attendancesHeaders: [{ text: "inTime", value: "attendance.inTime" }],
    gender: [
      { text: "Male ", value: 0 },
      { text: "Female ", value: 1 }
    ],
    editedItem: {
      memberNumber: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      telephone: "",
      mobile: "",
      gender: 0
    },
    dialogDelete: false,
    dialog: false,
    memberInfo: "",
    dateModal: false,
    datePick: "",
    initialFinal: "",

    itemsFormatted: [],
    fileTitle: "",
    csvHeaders: {
      //createdAt: "Created Time",
      inTime: "In Time",
      outTime: "Out Time"
    }
  }),

  computed: {},
  watch: {},
  filters: {
    moment: function(date) {
      return moment
        .utc(date)
        .local()
        .format("MMMM Do YYYY - h:mm a");
    }
  },
  created() {
    this.getData();
  },
  methods: {
    downloadCSV() {
      this.fileTitle =
        this.memberInfo.memberNumber +
        "_" +
        this.memberInfo.firstName +
        "_Attendaces";
      this.memberInfo.attendances.forEach(item => {
        var inTime = moment(item.inTime).format("MMMM Do YYYY - h:mm a");
        var outTime = moment(item.outTime).format("MMMM Do YYYY - h:mm a");
        this.itemsFormatted.push({
          // memberId: item.memberId,
          //  createdAt: item.createdAt,
          inTime: inTime,
          outTime: outTime
        });
      });
      console.log(this.itemsFormatted);
      this.exportCSVFile(this.csvHeaders, this.itemsFormatted, this.fileTitle);
    },
    exportCSVFile(headers, items, fileTitle) {
      console.log("exportCSVFile");

      console.log(headers);
      console.log(items);

      console.log(fileTitle);

      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle || "export.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      this.clearData();
    },
    convertToCSV(objArray) {
      console.log("convertToCSV");
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    clearData() {
      this.itemsFormatted = [];
      console.log("Cleared");
    },

    backToMenu() {
      this.$router.go(-1);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MMMM Do YYYY - h:mm a");
      }
    },

    getData() {
      this.isLoading = true;
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let itemId = vars[0];
      itemId = parseInt(itemId);

      this.$http.get(`/Member/${itemId}`).then(response => {
        this.memberInfo = response.data.data;
        console.log(this.memberInfo);
        this.isLoading = false;
      });
    }
  }
};
</script>
<style scoped>
.date-modal {
  overflow: hidden;
}
</style>
